import React from 'react';

const ChangePasswordIcon = ({ width = 20, height = 20, className = ""}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 24 24"
        width={width}
        height={height}
    >
        <path d="M18.8646 7.3739C19.6046 10.3419 17.7022 13.3717 14.6156 14.1413C14.2291 14.2377 13.8425 14.294 13.4602 14.3131L10.025 19.7842C9.7502 20.222 9.31658 20.5363 8.81507 20.6613L6.84105 21.1535C6.30517 21.2871 5.76244 20.961 5.62883 20.4251L5.1835 18.639C5.05189 18.1111 5.14099 17.5524 5.43028 17.0917L8.5872 12.0637C8.1742 11.5092 7.86331 10.8688 7.68681 10.1608C6.94681 7.1929 8.84917 4.16302 11.9358 3.39343C15.0225 2.62384 18.1246 4.40596 18.8646 7.3739ZM14.2375 8.69804C14.9234 8.52702 15.3462 7.85371 15.1817 7.19417C15.0173 6.53463 14.3279 6.1386 13.642 6.30962C12.9561 6.48064 12.5333 7.15395 12.6978 7.81349C12.8622 8.47304 13.5516 8.86906 14.2375 8.69804Z"/>
    </svg>
);

export default ChangePasswordIcon;
